import React, { Fragment } from 'react';
import apiService from '../../store/shared/services/apiService';
import { SkeletonLoader } from '../components/loader/SkeletonLoader';
import Loading from '../components/loading';
import Pagination from '../components/pagination';

const isEqual = require('react-fast-compare');

const ListHOC = ({
  endpoint = '',
  params = {},
  defaultMessage = 'List is Empty',
  type = 'list',
  sortByList = [],
  title = '',
  preloadedData,
  columnSize,
  imageExists,
}) => (WrappedComponent) =>
  (class List extends React.PureComponent {
    constructor(props) {
      super(props);
      this.apiParams = this.props.apiParams || { endpoint: '', params: {} };
      this.pageSize =
        this.apiParams.params && this.apiParams.params.page_size
          ? this.apiParams.params.page_size
          : 20;
      this.columnSize =
        columnSize || (this.props.columnSize ? this.props.columnSize : 4);
      this.state = {
        data: [],
        paging: {},
        loading: true,
        currentPage: 1,
      };
    }

    toggleLoading = (flag = false) => {
      this.setState({ loading: flag });
    };

    componentDidMount() {
      this.fetchData();
    }

    componentDidUpdate(prevProps) {
      if (!isEqual(this.props.apiParams, prevProps.apiParams)) {
        this.apiParams = this.props.apiParams || { endpoint: '', params: {} };
        this.fetchData();
      }
    }

    fetchData = (pageParams = { page: 1, page_size: this.pageSize }) => {
      this.toggleLoading(true);
      apiService
        .request('GET', endpoint || this.apiParams.endpoint, {
          ...params,
          ...this.apiParams.params,
          ...pageParams,
        })
        .then((resp) => {
          const paging =
            resp.meta && resp.meta.paging ? { ...resp.meta.paging } : {};
          this.setState({ data: [...resp.data], paging });
        })
        .catch((err) => console.log(err))
        .finally(() => this.toggleLoading());
    };

    render() {
      const { loading, data, paging } = this.state;

      if (!loading && data.length === 0) {
        return (
          <p className="text-center">
            {this.props.renderEmptyListText
              ? this.props.renderEmptyListText()
              : defaultMessage}
          </p>
        );
      }
      const items = data.map((item) => (
        <div
          key={item._id}
          className={
            type === 'grid'
              ? `col-6 col-sm-4 col-lg-${this.columnSize} col-xl-${this.columnSize} col-md-4 `
              : undefined
          }
          style={
            type === 'grid'
              ? {display: 'flex'} : undefined
          }
        >
          <WrappedComponent
            item={{ ...item }}
            type={type}
            {...this.props}
            reloadList={() => this.fetchData()}
          />
        </div>
      ));
      return (
        <>
          <>
            {loading ? (
              type === 'grid' || type === 'list' ? (
                <SkeletonLoader
                  type={type}
                  contentType={
                    endpoint === 'categories/featured' ? 'featured' : null
                  }
                  showImage={
                    this.apiParams.endpoint !== 'transactions' || imageExists
                  }
                  columnSize={this.columnSize}
                  height={type === 'grid' ? 200 : 140}
                  width={200}
                  count={this.pageSize}
                />
              ) : (
                <Loading />
              )
            ) : type === 'grid' ? (
              <div className="row featured-cat">{items}</div>
            ) : (
              items
            )}
            {data.length > 0 && Object.keys(paging).length > 0 ? (
              <Pagination
                {...paging}
                pageNeighbours={3}
                onPageChanged={(pageData) =>
                  this.fetchData({
                    page: pageData.page,
                    page_size: pageData.page_size,
                  })
                }
                height={type === 'grid' ? 200 : 140}
                width={200}
                count={this.pageSize}
              />
            ) : (
              ''
            )}

            <div className="d-flex justify-content-end" />
          </>
        </>
      );
    }
  });

export default ListHOC;
